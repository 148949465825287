<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterBrand
                    style="width:100%;"
                    v-model:value="state.params.brand"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Tanggal Mulai"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Tanggal Akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Laporan-Detail-Transaksi-Distributor"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterBrand,
    FilterDistributor,
} from '@/components/filter'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import moment from 'moment'
import {
    updateUrlWithQuery,
    extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        DownloadExcel,
        TableStandard,
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterBrand,
        FilterDistributor,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat Toko',
                    dataIndex: 'customer_address',
                },
                {
                    title: 'Kabupaten Toko',
                    dataIndex: 'customer_kabupaten',
                },
                {
                    title: 'Provinsi Toko',
                    dataIndex: 'customer_propinsi',
                },
                {
                    title: 'Regional Toko',
                    dataIndex: 'customer_regional',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'customer_area',
                },

                {
                    title: 'Brands',
                    dataIndex: 'brand_name',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product_name',
                },
                {
                    title: 'Harga',
                    dataIndex: 'price',
                },
                {
                    title: 'Zak Quantity ',
                    dataIndex: 'quantity_zak',
                },
                {
                    title: 'UOM 1',
                    dataIndex: 'uom1',
                },
                {
                    title: 'TON Quantity ',
                    dataIndex: 'quantity_ton',
                },
                {
                    title: 'UOM 2',
                    dataIndex: 'uom2',
                },
                {
                    title: 'No Transaksi',
                    dataIndex: 'transaksi_code',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Kode Gudang ',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang ',
                    dataIndex: 'warehouse_name',
                },
                {
                    title: 'Regional Gudang',
                    dataIndex: 'regional_name',
                },
                {
                    title: 'Provinsi Gudang',
                    dataIndex: 'warehouse_propinsi',
                },
                {
                    title: 'Area Gudang',
                    dataIndex: 'warehouse_area',
                },
                {
                    title: 'Kabupaten Gudang',
                    dataIndex: 'warehouse_kabupaten',
                },
            ],
            endpoint:'/api/report/detail-trx-distributor-v2',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: extractQueryParams({
                q: '',
                brand: [],
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                distributor: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()),
                page: 1,
                "per-page": 10,
            }),
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            state,
            errorMessage,
            fetchDataList,
            queryParams,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>
